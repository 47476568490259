"use client";

import "/public/fuegostats.css";
import "../theme.scss";
import { Archivo } from "next/font/google"; // https://fonts.google.com/variablefonts
import { ReactQueryClientProvider } from "../components/ReactQueryClientProvider";
import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import BugsnagPerformance from "@bugsnag/browser-performance";
import Script from "next/script";
import ModalWhatsNewAlert from "../components/modals/ModalWhatsNewAlert";
const NEXT_PUBLIC_GOOGLE_ANALYTICS = process.env.NEXT_PUBLIC_MEASUREMENT_ID;

Bugsnag.start({
  apiKey: "1802886be0eea9d7831620390feeadef",
  plugins: [new BugsnagPluginReact()],
});
BugsnagPerformance.start({ apiKey: "1802886be0eea9d7831620390feeadef" });

const bodyFont = Archivo({
  weight: "400",
  subsets: ["latin"],
});

export default function RootLayout({ children }: React.PropsWithChildren<{}>) {
  const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

  return (
    <ReactQueryClientProvider>
      <html lang="en" /*data-bs-theme="dark"*/>
        <head>
          <title>Fuego Stats</title>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
            strategy="afterInteractive"
          />
          <Script id="google-analytics" strategy="afterInteractive">
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${NEXT_PUBLIC_GOOGLE_ANALYTICS}');
          `}
          </Script>
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favicon-16x16.png"
          />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#848484" />
          <meta name="msapplication-TileColor" content="#ff0000" />
          <meta name="theme-color" content="#ffffff" />
        </head>
        <body className={bodyFont.className}>
          <ErrorBoundary>{children}</ErrorBoundary>
        </body>
      </html>
      <ModalWhatsNewAlert />
    </ReactQueryClientProvider>
  );
}
