import { Modal } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { IconFuegoStats } from "../icons";
import { FaStopwatch } from "react-icons/fa";

const fsLocalInfo = "fuegostat-app-info";

const ModalWhatsNewAlert = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setTimeout(function () {
      window.location.reload();
    }, 1000);
  };
  const pathname = usePathname();

  useEffect(() => {
    const jsonValue = JSON.parse(localStorage.getItem(fsLocalInfo));
    let localVersion = "0.0.0";

    if (jsonValue != null) {
      localVersion = jsonValue.version;
    }

    const checkVersion = async () => {
      let serverVersion: string = "0.0.1";

      try {
        const res = await fetch("/api/version");
        if (!res.ok) throw new Error("Failed to fetch version");

        const data = (await res.json()) as { version: string };
        serverVersion = data.version;

        if (localVersion !== serverVersion) {
          setShow(true);
          localStorage.setItem(
            fsLocalInfo,
            JSON.stringify({ version: serverVersion })
          );
        } else {
          setShow(false);
        }
      } catch (error) {
        console.error("Error fetching version:", error);
      }
    };

    // uncomment the line below when ready to activate this functionality
    // checkVersion();
  }, [pathname]);

  return (
    <Modal show={show} onHide={handleClose} backdrop={true}>
      <Modal.Header className="border-0 pb-1" closeButton>
        <Modal.Title className="d-flex align-items-center justify-content-center w-100">
          <IconFuegoStats size={24} className="me-1" />
          <span>What&rsquo;s New</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center px-5">
        <p>
          We&rsquo;ll occasionally use this notification when we&rsquo;ve added
          new features that we hope you&rsquo;ll love!
        </p>
        <p className="d-flex-inline align-items-center">
          <span>
            Hopefully, your team is using new{" "}
            <span className="text-primary">cap timer</span>.
          </span>
          <FaStopwatch className="text-muted ms-1" />
        </p>
        <p className="mb-0">
          Check out the
          <Link href="/whatsnew" className="mx-1" onClick={handleClose}>
            What&apos;s New
          </Link>
          page below the user dropdown for more...
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default ModalWhatsNewAlert;
